<template>
  <div class="box">
    <div class="headline">涉及范畴</div>
    <div class="content">
      <p>进入涉及范畴界面，【基础资料】→【涉及范畴】，如下图</p>
      <img src="../../assets/specificationImgs/img24.png" alt width="100%" />
      <p>左侧是添加一级范畴信息，中间用树形图直观显示了涉及范畴的从属关系。</p>
      <p>
        1）新增：先通过左侧点击《新增一级范畴》输入《编号》、《名称》添加涉及范畴，再点击“添加下级范畴”，输入《编号》、《名称》，再选择《上级范畴》即可成功添加下级范畴。 <br />
        2）修改：在中间选中某一个涉及范畴信息，右边则会显示该节点的详细信息，再按需修改涉及范畴信息，点击“修改”即可完成对设计范畴信息的修改。<br />
        3）删除：选中中间树型的某一节点，再点击“垃圾桶”即可完成对设计范畴信息的删除。
      </p>
      <p>
        操作技巧：<br />
        1）“新增”或“修改”后，不需要保存，点击“取消”。 <br />
        2）删除选中的节点时，也会删除该节点下的所有涉及范畴信息。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
